import { Viewer } from "../3DViewer/Viewer";
// import "@egjs/react-view3d/css";
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from "react-router-dom";
import { Privacy } from "./Privacy";
import { InitializeFirebase } from "../Firebase/firebase";
import ContactForm from "./AccountDeletion";
export const Home = () => {
    InitializeFirebase();
    return (
        <Router>
            <Routes>
                <Route exact path="/" element={<Viewer />} />
                <Route path="/balldestiny/privacy" element={<Privacy />} />
                <Route path="/balldestiny/accountdelete" element={<ContactForm />} />
            </Routes>
        </Router>);
}