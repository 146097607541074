import React from 'react';
import { useForm } from 'react-hook-form';

const RequestForm = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const onSubmit = (data) => {
        console.log(data); 
        const a = document.createElement("a");
        var body = `Email: ${data.email} \nDescription: ${data.description}`
       // Encode the string for use in a mailto link
       var encodedBody = encodeURIComponent(body);
        a.href = `mailto:vibesinfodelet@gmail.com?subject=Account%20Deletion%20Request&body=${encodedBody}`;
        a.style.display = "none";
        a.click();
        // Handle form submission here (e.g., send data to server)
    };

    return (
        <div className="form-container">
            <h2>Account Delete</h2> {/* Added title */}
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                        type="email"
                        id="email"
                        {...register('email', { required: 'Email is required', pattern: /^\S+@\S+\.\S+$/i })}
                    />
                    {errors.email && <span className="error">{errors.email.message}</span>}
                </div>
                <div className="form-group">
                    <label htmlFor="description">Request Description:</label>
                    <textarea id="description" {...register('description', { required: 'Description is required' })} />
                    {errors.description && <span className="error">{errors.description.message}</span>}
                </div>
                <button type="submit">
                    Submit Request</button>
            </form>
        </div>
    );
};

export default RequestForm;