import * as THREE from 'three';
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
let gltfLoader = new GLTFLoader();
let dracoLoader = new DRACOLoader();
const renderer = new THREE.WebGLRenderer();
const scene = new THREE.Scene();
// Setting up camera
window.three = THREE

const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
);
window.camera = camera

const orbit = new OrbitControls(camera, renderer.domElement);
export const Viewer = () => {
    viewerAsync()
    return <></>
}

window.scene = scene
const viewerAsync = async () => {
    // Setting up renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    document.body.appendChild(renderer.domElement);
    renderer.setClearColor(0xffffff, 0);
    camera.position.set(-1,1,5)
    orbit.update();
    // Loads HDR
    await loadHDR("studio_small_08_1k.hdr", false)

    // Loads Entire Scene
    let sceneGltf = await loadGltf("screen.glb");
    scene.add(sceneGltf.scene);

    window.addEventListener('resize', onWindowResize, false);
    // Scroll animation
    animate();
}

const onWindowResize = () => {

    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);

}
const animate = () => {
    requestAnimationFrame(animate);
    orbit.update()
    render();
}
const render = () => {
    renderer.render(scene, camera);
}
const loadHDR = async (hdrPath, isBackground = true, isEnvironment = true) => {
    let loader = new RGBELoader().setPath("HDRI/");
    let texture = await loader.loadAsync(hdrPath);
    texture.mapping = THREE.EquirectangularReflectionMapping;
    if (isBackground) {
        scene.background = texture;
    }
    if (isEnvironment) {
        scene.environment = texture;
    }
    render();
    return texture;
}
const loadBackground = async (backgroundPath) => {
    let loader = new THREE.TextureLoader().setPath("HDRI/");
    let texture = await loader.loadAsync(backgroundPath);
    scene.background = texture;
    render();
    return texture;
}

/**
 * Loads a GLTF model asynchronously.
 *
 * @param {string} modelName - The name of the GLTF model to load.
 * @return {Promise} A promise that resolves with the loaded GLTF model or rejects with an error.
 */
const loadGltf = async (modelName) => {
    dracoLoader.setDecoderConfig({ type: 'js' })
    dracoLoader.setDecoderPath("/draco/gltf/")
    dracoLoader.preload()

    gltfLoader = gltfLoader.setDRACOLoader(dracoLoader)

    return new Promise(async (resolve, reject) => {
        try {
            let gltf = await gltfLoader.setPath("Model/").loadAsync(modelName, (event) => {
            });
            resolve(gltf);
        } catch (error) {
            reject(error)
        }
    })
}